<template>
  <div class="pool">
    <div class="pool__box">
      <div class="pool__svg">
        <svg width="333" height="277" viewBox="0 0 333 277" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M318.928 179.764C311.086 166.819 311.086 150.588 318.928 137.643C324.305 128.764 321.676 117.221 312.984 111.549L312.939 111.519C300.294 103.267 293.265 88.6843 294.687 73.6515L294.694 73.5733C295.671 63.2503 288.295 54.0092 278.012 52.6719L277.975 52.6672C262.978 50.717 250.302 40.601 245.074 26.4093L245.07 26.3994C241.481 16.6588 230.816 11.5179 220.961 14.7782L220.917 14.7928C206.579 19.5364 190.794 15.9397 179.925 5.4542L179.866 5.39649C172.408 -1.79883 160.592 -1.79883 153.134 5.39649L153.074 5.4542C142.206 15.9404 126.42 19.5364 112.083 14.7928L112.04 14.7789C102.184 11.5185 91.5191 16.6594 87.9304 26.4L87.9271 26.41C82.6986 40.6016 70.024 50.7177 55.0257 52.6679L54.9886 52.6725C44.706 54.0098 37.3303 63.2509 38.3067 73.5739L38.314 73.6522C39.7356 88.685 32.7074 103.267 20.062 111.52L20.0163 111.55C11.3238 117.223 8.69494 128.766 14.0727 137.644C21.9148 150.59 21.9148 166.82 14.0727 179.766L318.928 179.764Z" fill="url(#paint0_linear_653_563)"/>
          <path d="M46.7178 158.706C46.7178 92.5521 100.346 38.9238 166.5 38.9238C232.653 38.9238 286.281 92.5521 286.281 158.706H46.7178Z" fill="url(#paint1_linear_653_563)"/>
          <path d="M27.7498 151.169C45.4459 143.457 65.5532 143.457 83.25 151.169C100.946 158.881 121.053 158.881 138.75 151.169C156.446 143.457 176.554 143.457 194.25 151.169C211.947 158.881 232.054 158.881 249.751 151.169C267.447 143.457 287.554 143.457 305.251 151.169C322.103 158.513 333 175.149 333 193.533V211.515L166.5 244.158L0 211.516V193.533C0 175.149 10.8968 158.513 27.7498 151.169Z" fill="url(#paint2_linear_653_563)"/>
          <path d="M297.296 176.048C296.412 176.048 295.513 175.87 294.65 175.494C290.922 173.869 287.007 172.785 283.016 172.27C279.382 171.802 276.817 168.476 277.285 164.842C277.754 161.209 281.081 158.643 284.713 159.112C289.946 159.787 295.073 161.206 299.949 163.331C303.308 164.794 304.845 168.703 303.381 172.062C302.294 174.558 299.856 176.048 297.296 176.048Z" fill="#BFE7F8"/>
          <path d="M245.31 180.347C242.442 180.347 239.797 178.472 238.948 175.58C237.916 172.065 239.929 168.379 243.444 167.347C248.305 165.92 253.248 164.188 258.028 162.514C259.05 162.156 260.072 161.798 261.095 161.442C264.555 160.239 268.336 162.068 269.54 165.528C270.743 168.988 268.913 172.77 265.453 173.973C264.44 174.325 263.427 174.68 262.413 175.035C257.475 176.764 252.369 178.553 247.182 180.076C246.558 180.259 245.929 180.347 245.31 180.347Z" fill="#BFE7F8"/>
          <path d="M102.275 133.413C108.304 133.413 113.191 129.486 113.191 124.641C113.191 119.797 108.304 115.869 102.275 115.869C96.2468 115.869 91.3599 119.797 91.3599 124.641C91.3599 129.486 96.2468 133.413 102.275 133.413Z" fill="#FD9BAE"/>
          <path d="M230.726 133.413C236.754 133.413 241.641 129.486 241.641 124.641C241.641 119.797 236.754 115.869 230.726 115.869C224.698 115.869 219.811 119.797 219.811 124.641C219.811 129.486 224.698 133.413 230.726 133.413Z" fill="#FD9BAE"/>
          <path d="M120.988 105.897C126.237 105.897 131.082 107.059 134.988 109.02C138.309 110.687 142.268 108.435 142.563 104.731C142.646 103.691 142.654 102.63 142.584 101.554C141.86 90.4858 132.644 81.6467 121.556 81.3628C109.343 81.0497 99.3433 90.8572 99.3433 103C99.3433 103.616 99.3691 104.226 99.4202 104.829C99.7287 108.49 103.7 110.671 106.983 109.022C110.89 107.059 115.737 105.897 120.988 105.897Z" fill="white"/>
          <path d="M166.5 132.084C161.788 132.084 157.077 130.509 153.146 127.36C149.499 124.438 148.912 119.115 151.833 115.469C154.754 111.823 160.078 111.236 163.724 114.156C165.358 115.466 167.641 115.466 169.276 114.156C172.921 111.236 178.245 111.823 181.166 115.469C184.087 119.116 183.499 124.439 179.853 127.36C175.922 130.509 171.211 132.084 166.5 132.084Z" fill="#383842"/>
          <path d="M120.988 105.898C125.319 105.898 129.373 106.689 132.854 108.066C133.52 106.51 133.889 104.799 133.889 103C133.889 95.8754 128.113 90.0996 120.988 90.0996C113.864 90.0996 108.088 95.8754 108.088 103C108.088 104.799 108.457 106.51 109.123 108.066C112.604 106.689 116.658 105.898 120.988 105.898Z" fill="#383842"/>
          <path d="M212.012 105.897C217.262 105.897 222.107 107.059 226.013 109.02C229.334 110.687 233.293 108.435 233.588 104.731C233.671 103.691 233.679 102.63 233.609 101.554C232.885 90.4858 223.669 81.6467 212.581 81.3628C200.367 81.0497 190.368 90.8572 190.368 103C190.368 103.616 190.394 104.226 190.445 104.829C190.753 108.49 194.725 110.671 198.008 109.022C201.916 107.059 206.762 105.897 212.012 105.897Z" fill="white"/>
          <path d="M212.012 105.898C216.343 105.898 220.397 106.689 223.878 108.066C224.544 106.51 224.913 104.799 224.913 103C224.913 95.8754 219.137 90.0996 212.012 90.0996C204.888 90.0996 199.112 95.8754 199.112 103C199.112 104.799 199.48 106.51 200.146 108.066C203.628 106.689 207.682 105.898 212.012 105.898Z" fill="#383842"/>
          <path d="M0 211.514V249.406C0 264.534 12.264 276.798 27.3922 276.798H305.608C320.736 276.798 333 264.534 333 249.406V211.514H0Z" fill="url(#paint3_linear_653_563)"/>
          <path d="M55.28 211.514H0V229.654H55.28C60.2896 229.654 64.3499 225.593 64.3499 220.584C64.3506 215.574 60.2896 211.514 55.28 211.514Z" fill="#F7AC3E"/>
          <defs>
          <linearGradient id="paint0_linear_653_563" x1="166.5" y1="-0.00116086" x2="166.5" y2="179.764" gradientUnits="userSpaceOnUse">
          <stop offset="0.0223" stop-color="#FCCB88"/>
          <stop offset="0.9994" stop-color="#F7AC3E"/>
          </linearGradient>
          <linearGradient id="paint1_linear_653_563" x1="46.7178" y1="98.8147" x2="286.281" y2="98.8147" gradientUnits="userSpaceOnUse">
          <stop offset="0.0223" stop-color="#F9F2AB"/>
          <stop offset="0.9994" stop-color="#F6E66A"/>
          </linearGradient>
          <linearGradient id="paint2_linear_653_563" x1="166.5" y1="244.158" x2="166.5" y2="145.384" gradientUnits="userSpaceOnUse">
          <stop offset="0.0223" stop-color="#A4E6FF"/>
          <stop offset="0.9994" stop-color="#5BD6FD"/>
          </linearGradient>
          <linearGradient id="paint3_linear_653_563" x1="166.5" y1="211.514" x2="166.5" y2="276.798" gradientUnits="userSpaceOnUse">
          <stop offset="0.0223" stop-color="#FCCB88"/>
          <stop offset="0.9994" stop-color="#F7AC3E"/>
          </linearGradient>
          </defs>
        </svg>
      </div>
      <div class="pool__info">
        <div class="pool__title">Бассейн</div>
        <div class="pool__text">
          Бассейн открытый, с артезианской водой, с системой двойной очистки и постоянной фильтрацией, размер 25Х10, глубиной от 0,8 м до 2,2 м, оснащен 2 каскадами, водной горкой и подогревом.
          <br /><br />
          Купание детей в бассейне начинается при температуре воды не ниже 24 градусов и температуре воздуха не ниже 26 градусов.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>
  .pool {
    background-color: #7bb8f3;
    background-image: url("~@/assets/images/photo/pool.png");
    background-size: cover;
    padding: 56px 0 82px;

    &__box {
      display: flex;
      justify-content: space-between;
      max-width: 1080px;
      margin: 0 auto;
      @media screen and (max-width: 1300px) {
        max-width: 620px;
        flex-wrap: wrap-reverse;
        justify-content: center;
      }
      @media screen and (max-width: 680px) {
        max-width: 300px;
      }
    }
    &__svg {
      margin: 0 82px 0 0;
      @media screen and (max-width: 1300px) {
        margin: 10px 0 0;
      }
      @media screen and (max-width: 680px) {
        margin: 30px 0 0;
        svg {
          width: 240px;
          height: 200px;
        }
      }
    }
    &__info {}
    &__title {
      font-family: RF Dewi Expanded;
      font-size: 64px;
      font-weight: 900;
      line-height: 77px;
      text-transform: uppercase;
      color: white;
      margin: 0 0 24px;
      @media screen and (max-width: 1300px) {
        font-size: 46px;
        line-height: 55px;
      }
      @media screen and (max-width: 680px) {
        font-size: 24px;
        line-height: 29px;
      }
    }
    &__text {
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      color: white;
      @media screen and (max-width: 1300px) {
        line-height: 23px;
      }
      @media screen and (max-width: 680px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
</style>