<template>
  <div class="regulations">
    <div class="regulations__title">Правила посещения</div>
    <p class="regulations__text">Правила посещения бассейна – своеобразный кодекс вежливости или даже этикет. Его задача сделать пребывание каждого посетителя комфортным и, конечно, обеспечить безопасность.</p>
    <div class="regulations__info">
      <ul>
        <li class="regulations__list">Приходить в бассейн ТОЛЬКО вместе со своим отрядом, в сопровождении вожатого.</li>
        <li class="regulations__list">Перед посещением бассейна необходимо принять душ и посетить туалетную комнату. </li>
        <li class="regulations__list">Девочкам необходимо забрать длинные волосы в тугой узел.</li>
        <li class="regulations__list">Все передвижения на территории бассейна осуществляются ТОЛЬКО шагом.</li>
        <li class="regulations__list">Входить и выходить из воды ТОЛЬКО с разрешения плаврука.</li>
        <li class="regulations__list">Нельзя: кричать, толкаться, прыгать в воду с бортов, приходить в бассейн с открытыми ссадинами на теле, плавать без допуска врача, бегать по парапету бассейна, нырять, мешать плаванию других.</li>
      </ul>
      <img src="@/assets/images/photo/regulations.jpg" alt="" class="regulations__img">
    </div>
    <div class="regulations-leisure__title">Досуг</div>
    <div class="regulations-leisure__box">
      <div class="regulations-leisure__item">
        <img src="@/assets/images/photo/regulations-leisure-1.jpg" alt="" class="regulations-leisure__item-image">
        <div class="regulations-leisure__item-box">
          <div class="regulations-leisure__item-title">Библиотека</div>
          <div class="regulations-leisure__item-text">
            Наша библиотека оснащена самыми разнообразными книгами на любой вкус для детей и подростков всех возрастных категорий.  Каждый ребенок сможет найти книгу для души и по школьным программам из более чем 3500 книг.
            <br /><br />
            Также лагерь всегда рад принять книги в дар!
          </div>
        </div>
      </div>
      <div class="regulations-leisure__item">
        <img src="@/assets/images/photo/regulations-leisure-2.jpg" alt="" class="regulations-leisure__item-image">
        <div class="regulations-leisure__item-box">
          <div class="regulations-leisure__item-title">Игротека</div>
          <div class="regulations-leisure__item-text">
            Наша игротека – мекка для ребятни! Она оснащена видеоприставками, различными настольными играми, аэрохоккеем, мини-футболом, большими напольными шахматами.
          </div>
        </div>
      </div>
      <div class="regulations-leisure__item">
        <img src="@/assets/images/photo/regulations-leisure-3.jpg" alt="" class="regulations-leisure__item-image">
        <div class="regulations-leisure__item-box">
          <div class="regulations-leisure__item-title">Видеосалон и караоке</div>
          <div class="regulations-leisure__item-text">
            Ежедневно в лагере работает видеосалон, караоке, где ребята могут посмотреть самые разнообразные детские фильмы и мультфильмы и попеть любимые популярные песни под минусовки.
          </div>
        </div>
      </div>
      <div class="regulations-leisure__item">
        <div class="regulations-leisure__item-image">
          <svg width="162" height="137" viewBox="0 0 205 137" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M96.8088 136.993C71.9266 136.993 51.9526 127.172 30.5301 107.801C25.6228 103.364 25.6228 95.6388 30.5301 91.2014C51.9526 71.831 71.9266 62.0098 96.8088 62.0098C121.691 62.0098 141.665 71.831 163.088 91.2014C167.995 95.6388 167.995 103.364 163.088 107.801C141.665 127.172 121.691 136.993 96.8088 136.993V136.993Z" fill="url(#paint0_linear_653_1476)"/>
          <path d="M59.3169 99.5067C59.3169 98.8717 59.3328 98.2404 59.3647 97.6135C59.5199 94.5422 57.3265 91.8503 54.2969 91.3227C50.6955 90.6958 47.3167 93.34 47.13 96.9908C47.0876 97.8243 47.0659 98.663 47.0659 99.5067C47.0659 109.085 49.7901 118.038 54.5002 125.637C67.4107 133.01 80.6774 136.806 95.7187 136.982C75.5168 136.405 59.3169 119.848 59.3169 99.5067V99.5067Z" fill="#B6C6D9"/>
          <path d="M96.8087 136.992C117.515 136.992 134.3 120.207 134.3 99.5011C134.3 78.7952 117.515 62.0098 96.8087 62.0098C76.1028 62.0098 59.3174 78.7952 59.3174 99.5011C59.3174 120.207 76.1028 136.992 96.8087 136.992Z" fill="url(#paint1_linear_653_1476)"/>
          <path d="M96.808 115.033C105.385 115.033 112.338 108.08 112.338 99.5028C112.338 90.9258 105.385 83.9727 96.808 83.9727C88.2309 83.9727 81.2778 90.9258 81.2778 99.5028C81.2778 108.08 88.2309 115.033 96.808 115.033Z" fill="url(#paint2_linear_653_1476)"/>
          <path d="M0.0782908 47.5282C-0.81399 38.5666 6.02983 30.5263 15.0347 30.6598C19.156 30.7211 22.8676 32.4661 25.5195 35.234C28.1715 32.4661 31.8831 30.7211 36.0043 30.6598C45.0092 30.5263 51.853 38.5662 50.9608 47.5282C49.6944 60.2505 36.855 69.9459 29.2479 73.8614C26.9055 75.0673 24.1331 75.0673 21.7907 73.8614C14.1841 69.9464 1.34464 60.2509 0.0782908 47.5282V47.5282Z" fill="url(#paint3_linear_653_1476)"/>
          <path d="M142.357 59.2754C151.228 59.2754 158.418 66.4659 158.418 75.336C158.418 81.6539 154.77 87.1194 149.466 89.742C146.192 91.3607 142.358 88.9285 142.358 85.2757V59.2754H142.357Z" fill="url(#paint4_linear_653_1476)"/>
          <path d="M192.125 79.0457H148.32C141.209 79.0457 135.445 73.2816 135.445 66.1711V46.6402C135.445 39.5297 141.209 33.7656 148.32 33.7656H192.125C199.235 33.7656 205 39.5297 205 46.6402V66.1711C205 73.2812 199.235 79.0457 192.125 79.0457Z" fill="url(#paint5_linear_653_1476)"/>
          <path d="M98.6847 47.6087L106.834 49.9519C111.083 51.1737 115.347 48.075 115.498 43.6569L115.788 35.1821C115.832 33.9068 116.233 32.6694 116.948 31.6122L121.695 24.5858C124.17 20.9228 122.541 15.9092 118.386 14.4003L110.415 11.5062C109.216 11.0705 108.163 10.306 107.378 9.29983L102.163 2.61406C99.4439 -0.871352 94.1723 -0.871352 91.4533 2.61406L86.2377 9.29983C85.4528 10.306 84.4005 11.0705 83.2011 11.5062L75.2306 14.4003C71.0754 15.9092 69.4465 20.9228 71.9212 24.5858L76.668 31.6122C77.3823 32.6694 77.7845 33.9068 77.8278 35.1821L78.1173 43.6569C78.2684 48.075 82.533 51.1733 86.7816 49.9519L94.931 47.6087C96.1577 47.2563 97.4584 47.2563 98.6847 47.6087V47.6087Z" fill="url(#paint6_linear_653_1476)"/>
          <path d="M174.402 48.2298H162.629C160.374 48.2298 158.545 46.4016 158.545 44.1462C158.545 41.8908 160.374 40.0625 162.629 40.0625H174.402C176.658 40.0625 178.486 41.8908 178.486 44.1462C178.486 46.4016 176.658 48.2298 174.402 48.2298Z" fill="#A1F5D0"/>
          <path d="M192.935 48.2298H191.033C188.778 48.2298 186.95 46.4016 186.95 44.1462C186.95 41.8908 188.778 40.0625 191.033 40.0625H192.935C195.19 40.0625 197.018 41.8908 197.018 44.1462C197.018 46.4016 195.19 48.2298 192.935 48.2298Z" fill="#A1F5D0"/>
          <defs>
          <linearGradient id="paint0_linear_653_1476" x1="96.8088" y1="142.509" x2="96.8088" y2="47.5136" gradientUnits="userSpaceOnUse">
          <stop stop-color="#BFDBDB"/>
          <stop offset="1" stop-color="#F3DBDB"/>
          </linearGradient>
          <linearGradient id="paint1_linear_653_1476" x1="96.8087" y1="136.993" x2="96.8087" y2="62.0098" gradientUnits="userSpaceOnUse">
          <stop stop-color="#4BA7E6"/>
          <stop offset="1" stop-color="#94CCF0"/>
          </linearGradient>
          <linearGradient id="paint2_linear_653_1476" x1="96.808" y1="115.033" x2="96.808" y2="83.9727" gradientUnits="userSpaceOnUse">
          <stop stop-color="#465260"/>
          <stop offset="1" stop-color="#5A708D"/>
          </linearGradient>
          <linearGradient id="paint3_linear_653_1476" x1="25.5195" y1="78.0108" x2="25.5195" y2="22.1315" gradientUnits="userSpaceOnUse">
          <stop stop-color="#EC6476"/>
          <stop offset="1" stop-color="#EC909C"/>
          </linearGradient>
          <linearGradient id="paint4_linear_653_1476" x1="150.388" y1="66.0159" x2="150.388" y2="89.3377" gradientUnits="userSpaceOnUse">
          <stop stop-color="#43B36B"/>
          <stop offset="0.196" stop-color="#46B570"/>
          <stop offset="0.4433" stop-color="#4DBC80"/>
          <stop offset="0.7181" stop-color="#5AC79A"/>
          <stop offset="1" stop-color="#6AD6BC"/>
          </linearGradient>
          <linearGradient id="paint5_linear_653_1476" x1="170.222" y1="38.1674" x2="170.222" y2="81.7434" gradientUnits="userSpaceOnUse">
          <stop stop-color="#64CD89"/>
          <stop offset="0.2218" stop-color="#66CF8E"/>
          <stop offset="0.5016" stop-color="#6DD39E"/>
          <stop offset="0.8113" stop-color="#77DBB8"/>
          <stop offset="1" stop-color="#7FE1CB"/>
          </linearGradient>
          <linearGradient id="paint6_linear_653_1476" x1="96.8083" y1="45.6873" x2="96.8083" y2="-4.95869" gradientUnits="userSpaceOnUse">
          <stop stop-color="#F4D84A"/>
          <stop offset="1" stop-color="#F9EA9D"/>
          </linearGradient>
          </defs>
          </svg>
        </div>
        <div class="regulations-leisure__item-box">
          <div class="regulations-leisure__item-title">Микс-студия и другие кружки</div>
          <div class="regulations-leisure__item-text">
            В лагере работают разнообразные кружки: микс-студия, где дети учатся изготавливать поделки из различных материалов и рисовать, кружок оригами, кружок робототехники, секция йоги, секция футбола и другие.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>
  .regulations {
    max-width: 1080px;
    margin: 0 auto;
    padding: 74px 0 0;

    @media screen and (max-width: 1300px) {
      max-width: 620px;
    }
    @media screen and (max-width: 680px) {
      max-width: 300px;
    }

    &__title {
      font-family: RF Dewi Expanded;
      font-size: 32px;
      font-weight: 900;
      line-height: 38px;
      text-align: center;
      text-transform: uppercase;
      @media screen and (max-width: 1300px) {
        font-size: 24px;
        line-height: 29px;
      }
      @media screen and (max-width: 680px) {
        font-size: 18px;
        line-height: 22px;
      }
    }
    &__text {
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 400;
      line-height: 23px;
      @media screen and (max-width: 1300px) {
        font-size: 18px;
        line-height: 23px;
      }
      @media screen and (max-width: 680px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
    &__info {
      display: flex;
      justify-content: space-between;
      ul {
        padding-inline-start: 10px;
      }
      @media screen and (max-width: 1300px) {
        flex-wrap: wrap;
      }

    }
    &__list {
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 400;
      line-height: 23px;
      margin: 0 0 18px;
      @media screen and (max-width: 1300px) {
        flex-wrap: wrap;
        font-size: 18px;
        line-height: 23px;
      }
      @media screen and (max-width: 680px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
    &__img {
      margin: 0 0 0 14px;
      border-radius: 20px;
      width: 510px;
      object-fit: contain;
      @media screen and (max-width: 1300px) {
        width: 100%;
        margin: 0;
      }
    }
    &-leisure {
      &__title {
        font-family: RF Dewi Expanded;
        font-size: 64px;
        font-weight: 900;
        line-height: 77px;
        text-transform: uppercase;
        padding: 116px 0 82px;
        @media screen and (max-width: 1300px) {
          padding: 60px 0 54px;
          font-size: 46px;
          line-height: 55px;
        }
        @media screen and (max-width: 680px) {
          padding: 72px 0 40px;
          font-size: 24px;
          line-height: 29px;
        }
      }
      &__box {
      }
      &__item {
        display: flex;
        justify-content: space-around;
        padding: 62px 40px 46px;
        border-top: 1px solid #DFDFDF;
        @media screen and (max-width: 1300px) {
          padding: 34px 20px 38px;
          align-items: center;
        }
        @media screen and (max-width: 680px) {
          flex-wrap: wrap;
          justify-content: flex-start;
          padding: 24px 10px 34px;
        }

        &-box {
          display: flex;
          flex-wrap: wrap;
          @media screen and (max-width: 680px) {
            width: 100%;
          }
        }
        &-image {
          width: 162px;
          padding: 0 58px 0 0;
          @media screen and (max-width: 1300px) {
            width: 160px;
            height: 160px;
          }
          @media screen and (max-width: 680px) {

          }
        }
        &-title {
          font-family: RF Dewi Expanded;
          font-size: 24px;
          font-weight: 900;
          line-height: 29px;
          width: 100%;
          text-transform: uppercase;
          @media screen and (max-width: 1300px) {
            font-size: 22px;
            line-height: 27px;
            padding: 0 0 16px;
          }
          @media screen and (max-width: 680px) {
            padding: 30px 0 18px;
            font-size: 18px;
            line-height: 22px;
          }
        }
        &-text {
          font-family: Montserrat;
          font-size: 18px;
          font-weight: 400;
          line-height: 23px;
          width: 100%;
          @media screen and (max-width: 680px) {
            font-size: 14px;
            line-height: 18px;
          }
        }
        &:nth-child(1) {
          .regulations-leisure__item-title {
            color: #4BD877;
          }
        }
        &:nth-child(2) {
          .regulations-leisure__item-title {
            color: #977ECF;
          }
        }
        &:nth-child(3) {
          .regulations-leisure__item-title {
            color: #FB879C;
          }
        }
        &:nth-child(4) {
          border-bottom: 1px solid #DFDFDF;
          .regulations-leisure__item-title {
            color: #4DA8E6;
          }
        }
      }
    }
  }
</style>